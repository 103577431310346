import React, { useEffect, useRef } from "react";
import { Button, Intent } from "@blueprintjs/core";
import Router from "next/router";

const AMPLIFY_X = 3.0;
const AMPLIFY_Y = 1.25;

export default function NotFound() {
  const backImgRef = useRef<HTMLDivElement | null>(null);

  function handleParallax(event: MouseEvent) {
    const backImg = backImgRef.current;
    if (backImg) {
      // Converts it to [-1..1] range
      const relativeX = -(event.clientX / (document.body.clientWidth - 1) - 0.5) * 2;
      const relativeY = -(event.clientY / (document.body.clientHeight - 1) - 0.5) * 2;

      backImg.style.transform = [
        "translateX(",
        (relativeX * AMPLIFY_X).toFixed(4),
        "%) ",
        "translateY(",
        (relativeY * AMPLIFY_Y).toFixed(4),
        "%) ",
      ].join("");
    }
  }
  useEffect(() => {
    document.addEventListener("mousemove", handleParallax);
    return () => {
      document.removeEventListener("mousemove", handleParallax);
    };
  }, []);
  return (
    <div className="page-404-wrapper flex flex-column align-items-center">
      <div className="page-404-images">
        <div
          className="page-404-img"
          ref={backImgRef}
          style={{ backgroundImage: 'url("/static/404/back.png")' }}
        ></div>
        <div
          className="page-404-img page-404-non-parallax-img"
          style={{ backgroundImage: 'url("/static/404/front.png")' }}
        ></div>
        <div
          className="page-404-img page-404-non-parallax-img page-404-questions"
          style={{ backgroundImage: 'url("/static/404/questions.png")' }}
        ></div>
      </div>
      <h2>Oops... You're in the wrong place!</h2>
      <Button
        className="atcc--uppercase z-top"
        onClick={() => Router.push(`/`)}
        intent={Intent.PRIMARY}
        large={true}
        text="Go back home"
      />
    </div>
  );
}
